<template>
  <div class="knowledge">
    <div class="top">
      <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
      <div class="wenti-box">
        <div>选择选项</div>
        <div>
          <van-form>
            <van-field name="radio" label="术式:">
              <template #input>
                <van-radio-group v-model="operation" @change="reset" direction="horizontal">
                  <van-radio name="A">A.保乳</van-radio>
                  <van-radio name="B">B.全切</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field name="radio" label="Her2:">
              <template #input>
                <van-radio-group v-model="her2" @change="reset" direction="horizontal">
                  <van-radio name="A">A.阴性</van-radio>
                  <van-radio name="B">B.阳性</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field name="radio" label="激素受体:">
              <template #input>
                <van-radio-group v-model="hormone" @change="reset" direction="horizontal">
                  <van-radio name="A">A.阴性</van-radio>
                  <van-radio name="B">B.阳性</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <div style="margin: 16px; display: flex">
              <van-button round plain block type="info" @click="onReset" style="margin-right: .2rem">清空</van-button>
              <van-button round block type="info" native-type="submit" @click="onSubmit"style="margin-left: .2rem">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
    <div class="bottom" v-if="answer!=''">
      <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
      <div class="knowledge-box">
        <div class="title">
          <div>答案展示</div>
          <div v-if="answer=='AAA'">（保乳术后、Her2阴性、激素受体阴性）</div>
          <div v-if="answer=='BAA'">（全切术后，Her2阴性，激素受体阴性）</div>
          <div v-if="answer=='AAB'">（保乳术后，Her2阴性，激素受体阳性）</div>
          <div v-if="answer=='BAB'">（全切术后，Her2阴性，激素受体阳性）</div>
          <div v-if="answer=='BBA'">（全切术后，Her2阳性，激素受体阴性）</div>
          <div v-if="answer=='ABA'">（保乳术后，Her2阳性，激素受体阴性）</div>
          <div v-if="answer=='ABB'">（保乳术后，Her2阳性，激素受体阳性）</div>
          <div v-if="answer=='BBB'">（全切术后，Her2阳性，激素受体阳性）</div>
        </div>
        <div class="center" v-if="answer=='AAA'">
          早期 <popover title="高风险患者" :list="gfxTips" center="" />
          的治疗方案推荐使用
          <popover title="AC-T" :center="ACTTips" :list="list" />、<popover title="ddAC-ddT" :center="ddACddtTips" :list="list" />
          ，选择
          <popover title="AC-T" :center="ACTTips" :list="list" />
          ，每疗程21天，
          大概四个疗程，每疗程总花费大概3万元左右，选择 <popover title="ddAC-ddT" :center="ddACddtTips" :list="list" />方案，每疗程14天，大概四个疗程，
          每疗程总花费大概2.5万元左右。
          <popover title="低风险患者" :list="dfxTips" center="" />
          推荐
          <popover title="AC" :list="list" :center="ACTips" />
          或者<popover title="TC" :list="list" :center="TCTips" />方案，
          <popover title="AC" :list="list" :center="ACTips" />方案每疗程21天，大概四
          个疗程，每疗程总花费大概5000元左右。<popover title="TC" :list="list" :center="TCTips" />方案每疗程21天，大概四个疗程，每疗程总花费大概
          2000元左右。
          <div class="mid" @click="show= !show"><span class="font-bule">放疗方案点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show">
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>导管原位癌</td>
                <td>全乳放疗±瘤床加量</td>
              </tr>
              <tr>
                <td>浸润癌，腋窝淋巴结阴性</td>
                <td>
                  <p>1、全乳放疗（常规或大分割（首选））±瘤床加量；</p>
                  <p>2、部分乳腺短程照射（APBI）</p>
                </td>
              </tr>
              <tr>
                <td>浸润癌，腋窝淋巴结阳性，已清扫</td>
                <td>全乳放疗+瘤床加量+区域淋巴结放疗</td>
              </tr>
              <tr>
                <td>浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫</td>
                <td>全乳放疗（乳房高位切线野）+瘤床加量</td>
              </tr>
              <tr>
                <td>浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫</td>
                <td></td>
              </tr>
            </table>
            <div class="show-btn" @click="show=false">收起<van-icon name="arrow-up" /></div>
          </div>
          晚期患者的治疗方案分两种情况，蒽环类治疗失败的推荐单药紫杉类
          （白蛋白紫杉醇、多西他赛、紫杉醇）联合化疗（
          <popover title="TX" :center="TXTips" :list="list" />、
          <popover title="GT" :center="GTTips" :list="list" />、
          <popover title="TP" :center="TPTips" :list="list" />），
          此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱；蒽环类和
          紫衫类治疗失败的患者推荐是用单药化疗（卡培他滨、长春瑞滨、吉
          西他滨）联合
          <popover title="NP" :center="NPTips" :list="list" />、
          <popover title="GP" :center="GPTips" :list="list" />、
          <popover title="NX" :center="NXTips" :list="list" />化疗，此种情况大概每疗程大概花费千余元，
          具体疗程谨遵医嘱。
        </div>
        <div class="center" v-if="answer=='BAA'">
          早期<popover title="高风险患者" :list="gfxTips" center="" />的治疗方案推荐使用
          <popover title="AC-T" :center="ACTTips" :list="list" />、
          <popover title="ddAC-ddT" :center="ddACddtTips" :list="list" />，
          选择<popover title="AC-T" :center="ACTTips" :list="list" />，
          每疗程21天，大概四个疗程，每疗程总花费大概3万元左右，选择
          <popover title="ddAC-ddT" :center="ddACddtTips" :list="list" />方案，
          每疗程14天，大概四个疗程，每疗程总花费大概2.5万元左右。
          <popover title="低风险患者" :list="dfxTips" center="" />推荐
          <popover title="AC" :list="list" :center="ACTips" />
          或者
          <popover title="TC" :list="list" :center="TCTips" />方案，
          <popover title="AC" :list="list" :center="ACTips" />方案每疗程21天，大概四个疗程，每疗程总花费大概5000元左右。
          <popover title="TC" :list="list" :center="TCTips" />方案每疗程21天，大概四个疗程，每疗程总花费大概2000元左右。
         <div class="mid" @click="show= !show"><span class="font-bule">放疗方案点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show">
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>腋窝淋巴结清扫术后</p>
                  <p>1、T3—T4</p>
                  <p>2、腋窝淋巴结阳性</p>
                </td>
                <td>胸壁+区域淋巴结放疗</td>
              </tr>
              <tr>
                <td>前哨淋巴结阳性，未行腋窝淋巴结清扫</td>
                <td>
                  胸壁+包括腋窝在内的区域淋巴结放疗
                </td>
              </tr>

            </table>
            <div class="show-btn" @click="show=false">收起<van-icon name="arrow-up" /></div>
          </div>
          晚期患者的治疗方案分两种情况，蒽环类治疗失败的推荐单药紫杉类
          （白蛋白紫杉醇、多西他赛、紫杉醇）联合化疗（
          <popover title="TX" :center="TXTips" :list="list" />、
          <popover title="GT" :center="GTTips" :list="list" />、
          <popover title="TP" :center="TPTips" :list="list" />），
          此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱；蒽环类和
          紫衫类治疗失败的患者推荐是用单药化疗（卡培他滨、长春瑞滨、吉西
          他滨）联合
          <popover title="NP" :center="NPTips" :list="list" />、
          <popover title="GP" :center="GPTips" :list="list" />、
          <popover title="NX" :center="NXTips" :list="list" />化疗，此种情况大概每疗程大概花费千余元，
          具体疗程谨遵医嘱。
        </div>
        <div class="center" v-if="answer=='AAB'">
          早期 <popover title="高风险患者" :list="gfxTips" center="" />
          的治疗方案推荐使用
          <popover title="AC-T" :center="ACTTips" :list="list" />、
          <popover title="ddAC-ddT" :center="ddACddtTips" :list="list" />
          ，选择
          <popover title="AC-T" :center="ACTTips" :list="list" />
          ，每疗程21天，大概四个疗程，每疗程总花费大概3万元左右，选择 <popover title="ddAC-ddT" :center="ddACddtTips" :list="list" />方案，每疗程14天，大概四个疗程，
          每疗程总花费2.5万元左右。
          <popover title="低风险患者" :list="dfxTips" center="" />
          推荐
          <popover title="AC" :list="list" :center="ACTips" />
          或者<popover title="TC" :list="list" :center="TCTips" />方案，
          <popover title="AC" :list="list" :center="ACTips" />方案每疗程21天，大概四
          个疗程，每疗程总花费大概5000元左右。<popover title="TC" :list="list" :center="TCTips" />方案每疗程21天，大概四个疗程，每疗程总花费大概2000元左右。
          <div style="margin-top: .2rem">疗程完成后进行内分泌治疗</div>
          <div class="mid" @click="show= !show"><span class="font-bule">绝经前方案点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show">
            <div class="tab-title">初始治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>低复发风险患者（全满足）</p>
                  <p>1、淋巴结阴性</p>
                  <p>2、G1</p>
                  <p>3、T≤2cm</p>
                  <p>4、Ki-67低表达（≤30%）</p>
                </td>
                <td>TAM5年</td>
              </tr>
              <tr>
                <td>
                  <p>满足以下任意条件者</p>
                  <p>1、G2或G3</p>
                  <p>2、淋巴结阳性</p>
                  <p>3、T>2cm</p>
                </td>
                <td>
                  OFS+TAM5年
                </td>
              </tr>
              <tr>
                <td>
                  淋巴结阳性>=4个患者
                </td>
                <td>
                  OFS+AI5年
                </td>
              </tr>
            </table>
            <div  class="tab-title">延长治疗</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  完成TAM5年初始治疗且耐受良好患者
                </td>
                <td>
                  <p>1、未绝经患者：延长TAM治疗至10年</p>
                  <p>2、绝经患者：序贯AI治疗5年</p>
                </td>
              </tr>
              <tr>
                <td>
                  完成OFS+TAM5年初始治疗且耐受良好患者
                </td>
                <td>
                  绝经患者：序贯AI治疗
                </td>
              </tr>
              <tr>
                <td>
                  完成OFS+AI5年初始治疗且耐受良好患者
                </td>
                <td>
                  绝经患者：AI治疗
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show=false">收起<van-icon name="arrow-up" /></div>
          </div>
          <div class="mid" @click="show1= !show1"><span class="font-bule">绝经后方案点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show1">
            <div class="tab-title">初始治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  全部
                </td>
                <td>
                  <p>1、芳香化酶抑制剂（AI）5年</p>
                  <p>2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年</p>
                </td>
              </tr>
            </table>
            <div  class="tab-title">延长治疗</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  完成AI5年初始治疗且耐受良好低危患者
                </td>
                <td>
                  可停药
                </td>
              </tr>
              <tr>
                <td>
                  <p>完成AI5年初始治疗且耐受良好患者，存在以下情况：</p>
                  <p>（1）淋巴结阳性</p>
                  <p>（2）G3</p>
                  <p>（3）其他需要行辅助化疗的危险因素，如KI-67＞30%</p>
                </td>
                <td>
                  继续AI治疗
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show1=false">收起<van-icon name="arrow-up" /></div>
          </div>
          晚期患者的治疗方案分两种情况，蒽环类治疗失败的推荐单药紫杉类
          （白蛋白紫杉醇、多西他赛、紫杉醇）联合化疗（
          <popover title="TX" :center="TXTips" :list="list" />、
          <popover title="GT" :center="GTTips" :list="list" />、
          <popover title="TP" :center="TPTips" :list="list" />），
          此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱；蒽环类和
          紫衫类治疗失败的患者推荐是用单药化疗（卡培他滨、长春瑞滨、吉
          西他滨）联合
          <popover title="NP" :center="NPTips" :list="list" />、
          <popover title="GP" :center="GPTips" :list="list" />、
          <popover title="NX" :center="NXTips" :list="list" />化疗，此种情况大概每疗程大概花费千余
          元，具体疗程谨遵医嘱。
          <div class="mid" @click="show2=!show2"><span class="font-bule">化疗后开始内分泌治疗点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show2">
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  未经内分泌治疗
                </td>
                <td>
                  AI+CDK4/6抑制剂
                </td>
              </tr>
              <tr>
                <td>
                  TAM治疗失败
                </td>
                <td>
                  <p>AI+CDK4/6抑制剂</p>
                  <p>AI+HDAC抑制剂</p>
                  <p>氟维司群+CDK4/6抑制剂</p>
                </td>
              </tr>
              <tr>
                <td>
                  非甾体类AI治疗失败
                </td>
                <td>
                  <p>甾体类AI+HDAC抑制剂</p>
                  <p>氟维司群+CDK4/6抑制剂</p>
                </td>
              </tr>
              <tr>
                <td>
                  甾体类AI治疗失败
                </td>
                <td>
                  氟维司群+CDK4/6抑制剂
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show2=false">收起<van-icon name="arrow-up" /></div>
          </div>
        </div>
        <div class="center" v-if="answer=='BAB'">
          早期<popover title="高风险患者" :list="gfxTips" center="" />
          的治疗方案推荐使用
          <popover title="AC-T" :center="ACTTips" :list="list" />、
          <popover title="ddAC-ddT" :center="ddACddtTips" :list="list" />，
          选择 <popover title="AC-T" :center="ACTTips" :list="list" />
          ，每疗程21天，大概四个疗程，每疗程总花费大概3万元左右，选择
          <popover title="ddAC-ddT" :center="ddACddtTips" :list="list" />方案，
          每疗程14天，大概四个疗程，每疗程总花费大概2.5万元左右。
          <popover title="低风险患者" :list="dfxTips" center="" />推荐
          <popover title="AC" :list="list" :center="ACTips" />或者
          <popover title="TC" :list="list" :center="TCTips" />方案，
          <popover title="AC" :list="list" :center="ACTips" />方案每疗程21天，大概四个疗程，每疗程总花费大概5000元左右。
          <popover title="TC" :list="list" :center="TCTips" />
          方案每疗程21天，大概四个疗程，每疗程总花费大概2000元左右。
          <div style="margin-top: .2rem">化疗方案完成后进行内分泌治疗</div>
          <div class="mid" @click="show=!show"><span class="font-bule">绝经前方案点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show">
            <div class="tab-title">初始治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>低复发风险患者（全满足）</p>
                  <p>1、淋巴结阴性</p>
                  <p>2、G1</p>
                  <p>3、T≤2cm</p>
                  <p>4、Ki-67低表达（≤30%）</p>
                </td>
                <td>TAM5年</td>
              </tr>
              <tr>
                <td>
                  <p>满足以下任意条件者</p>
                  <p>1、G2或G3</p>
                  <p>2、淋巴结阳性</p>
                  <p>3、T>2cm</p>
                </td>
                <td>
                  OFS+TAM5年
                </td>
              </tr>
              <tr>
                <td>
                  淋巴结阳性>=4个患者
                </td>
                <td>
                  OFS+AI5年
                </td>
              </tr>
            </table>
            <div  class="tab-title">延长治疗</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  完成TAM5年初始治疗且耐受良好患者
                </td>
                <td>
                  <p>1、未绝经患者：延长TAM治疗至10年</p>
                  <p>2、绝经患者：序贯AI治疗5年</p>
                </td>
              </tr>
              <tr>
                <td>
                  完成OFS+TAM5年初始治疗且耐受良好患者
                </td>
                <td>
                  绝经患者：序贯AI治疗
                </td>
              </tr>
              <tr>
                <td>
                  完成OFS+AI5年初始治疗且耐受良好患者
                </td>
                <td>
                  绝经患者：AI治疗
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show=false">收起<van-icon name="arrow-up" /></div>
          </div>
          <div class="mid" @click="show1= !show1"><span class="font-bule">绝经后方案点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show1">
            <div class="tab-title">初始治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  全部
                </td>
                <td>
                  <p>1、AI5年</p>
                  <p>2、初始使用TAM治疗，可换用AI5年</p>
                </td>
              </tr>
            </table>
            <div  class="tab-title">延长治疗</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  完成AI5年初始治疗且耐受良好低危患者
                </td>
                <td>
                  可停药
                </td>
              </tr>
              <tr>
                <td>
                  <p>完成AI5年初始治疗且耐受良好患者，存在以下情况：</p>
                  <p>（1）淋巴结阳性</p>
                  <p>（2）G3</p>
                  <p>（3）其他需要行辅助化疗的危险因素，如KI-67＞30%</p>
                </td>
                <td>
                  继续AI治疗
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show1=false">收起<van-icon name="arrow-up" /></div>
          </div>
          晚期患者的治疗方案分两种情况，蒽环类治疗失败的推荐单药紫杉类
          （白蛋白紫杉醇、多西他赛、紫杉醇）联合化疗（
          <popover title="TX" :center="TXTips" :list="list" />、
          <popover title="GT" :center="GTTips" :list="list" />、
          <popover title="TP" :center="TPTips" :list="list" />），
          此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱；蒽环类和
          紫衫类治疗失败的患者推荐是用单药化疗（卡培他滨、长春瑞滨、吉
          西他滨）联合
          <popover title="NP" :center="NPTips" :list="list" />、
          <popover title="GP" :center="GPTips" :list="list" />、
          <popover title="NX" :center="NXTips" :list="list" />化疗，此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱。
          <div class="mid" @click="show2= !show2"><span class="font-bule">化疗后开始内分泌治疗点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show2">
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  未经内分泌治疗
                </td>
                <td>
                  AI+CDK4/6抑制剂
                </td>
              </tr>
              <tr>
                <td>
                  TAM治疗失败
                </td>
                <td>
                  <p>AI+CDK4/6抑制剂</p>
                  <p>AI+HDAC抑制剂</p>
                  <p>氟维司群+CDK4/6抑制剂</p>
                </td>
              </tr>
              <tr>
                <td>
                  非甾体类AI治疗失败
                </td>
                <td>
                  <p>甾体类AI+HDAC抑制剂</p>
                  <p>氟维司群+CDK4/6抑制剂</p>
                </td>
              </tr>
              <tr>
                <td>
                  甾体类AI治疗失败
                </td>
                <td>
                  氟维司群+CDK4/6抑制剂
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show2=false">收起<van-icon name="arrow-up" /></div>
          </div>
        </div>
        <div class="center" v-if="answer=='BBA'">
          <div class="frist-title">早期治疗方案推荐</div>
          <div class="line-height">未行辅助治疗的治疗方案选择</div>
          （1）腋窝淋巴结阳性，推荐
          <popover title="AC-THP" :center="ACTHPTips" :list="list" />、
          <popover title="TCbHP" :center="TCbHPTips" :list="list" />，
          <popover title="AC-THP" :center="ACTHPTips" :list="list" />方案大概四个疗程，
          每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他
          赛加双靶向用药，一般每疗程21天，使用大概一年时间，每疗程总花费大概在3.3万元左右；
          <popover title="TCbHP" :center="TCbHPTips" :list="list" />
          方案大概六个疗程，每个疗程21天，每疗程总花费大概在3.3万元左右。<br>
          （2）腋窝淋巴结阳性，伴以下危险因素之一：a.T>2cm；b.其他危险因素，
          如ER阴性，推荐
          <popover title="AC-TH" :center="ACTHTips" :list="list" />，
          <popover title="TCbH" :center="TCbHTips" :list="list" />，其中
          <popover title="AC-TH" :center="ACTHTips" :list="list" />方案蒽环类+环磷酰胺大概四个疗
          程，每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西
          他赛+曲妥珠单抗，其中曲妥珠单抗要连续使用一年，每疗程总花费大概在2.7万元左右；
          <popover title="TCbH" :center="TCbHTips" :list="list" />方案一般六个疗程，每疗程21天，其中曲妥珠单抗要连续使用一
          年。每疗程总花费大概在2.7万元左右。<br>
          （3）腋窝淋巴结阳性且T<2cm，推荐
          <popover title="TC+H" :center="TCbTips" :list="list" />，方案一般四个疗程，每疗程21天，
          其中曲妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。<br>
          （4）激素受体阳性，且无需化疗或不能耐受化疗者，二级推荐曲妥珠单抗+内分泌治疗。
          <div class="line-height">使用过新辅助化疗的：</div>
          <div class="tab-box" >
            <table>
              <tr>
                <th>术前抗HER-2治疗仅使用曲妥珠单抗</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  病理学完全缓解（pCR）
                </td>
                <td>曲妥珠单抗</td>
              </tr>
              <tr>
                <td>
                  病理学未完全缓解（non pCR）
                </td>
                <td>
                  T-DM1
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <th>术前抗HER-2治疗使用曲妥珠单抗联合帕妥珠单抗</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  病理学完全缓解（pCR）
                </td>
                <td>HP</td>
              </tr>
              <tr>
                <td>
                  病理学未完全缓解（non pCR）
                </td>
                <td>
                  T-DM1（恩美曲妥珠单抗）
                </td>
              </tr>
            </table>
          </div>
          <div class="frist-title">晚期治疗方案推荐</div>
          <div class="tab-box" >
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>1、未用过曲妥珠单抗</p>
                  <p>2、曾用曲妥珠单抗且符合再使用标准</p>
                </td>
                <td>
                  <p>THP</p>
                  <p>TXH</p>
                </td>
              </tr>
              <tr>
                <td>
                  曲妥珠单抗治疗失败
                </td>
                <td>
                  吡咯替尼+卡培他滨
                </td>
              </tr>
            </table>
          </div>
          注：<popover title="THP" :center="THPTips" :list="list" />：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概3.5万元左右，
          <popover title="TXH" :center="TXHTips" :list="list" />：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概2.7万元左右。
        </div>
        <div class="center" v-if="answer=='ABA'">
          <div class="frist-title">早期治疗方案推荐</div>
          <div  class="line-height">未行辅助治疗的治疗方案选择</div>
          （1）腋窝淋巴结阳性，推荐
          <popover title="AC-THP" :center="ACTHPTips" :list="list" />、
          <popover title="TCbHP" :center="TCbHPTips" :list="list" />，
          <popover title="AC-THP" :center="ACTHPTips" :list="list" />方案大概四个疗程，
          每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛加双靶向用药，
          一般每疗程21天，使用大概一年时间，每疗程总花费大概在3.3万元左右；
          <popover title="TCbHP" :center="TCbHPTips" :list="list" />方案大概六个疗程，
          每个疗程21天，每疗程总花费大概在3.3万元左右。<br>
          （2）腋窝淋巴结阳性，伴以下危险因素之一：a.T>2cm；b.其他危险因素，如ER阴
          性，推荐<popover title="AC-TH" :center="ACTHTips" :list="list" />，
          <popover title="TCbH" :center="TCbHTips" :list="list" />，其中
          <popover title="AC-TH" :center="ACTHTips" :list="list" />方案蒽环类+环磷酰胺大概四个疗程，每个疗程
          21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛+曲妥珠单抗，
          其中曲妥珠单抗要连续使用一年，每疗程总花费大概在2.7万元左右；<popover title="TCbH" :center="TCbHTips" :list="list" />
          方案一般六个疗程，每疗程21天，其中曲妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。<br>
          （3）腋窝淋巴结阳性且T<2cm，推荐<popover title="TC+H" :center="TCbTips" :list="list" />，方案一般四个疗程，
          每疗程21天，其中曲 妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。<br>
          （4）激素受体阳性，且无需化疗或不能耐受化疗者，二级推荐曲妥珠单抗+内分泌治疗。<br>
          <div class="line-height">使用过新辅助化疗的：</div>
          <div class="tab-box" >
            <table>
              <tr>
                <th>术前抗HER-2治疗仅使用曲妥珠单抗</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  病理学完全缓解（pCR）
                </td>
                <td>曲妥珠单抗</td>
              </tr>
              <tr>
                <td>
                  病理学未完全缓解（non pCR）
                </td>
                <td>
                  <p>HP</p>
                  <p>T-DM1</p>
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <th>术前抗HER-2治疗使用曲妥珠单抗联合帕妥珠单抗</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  病理学完全缓解（pCR）
                </td>
                <td>HP</td>
              </tr>
              <tr>
                <td>
                  病理学未完全缓解（non pCR）
                </td>
                <td>
                  T-DM1（恩美曲妥珠单抗）
                </td>
              </tr>
            </table>
          </div>
          <div>晚期治疗方案推荐</div>
          <div class="tab-box" >
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>1、未用过曲妥珠单抗</p>
                  <p>2、曾用曲妥珠单抗且符合再使用标准</p>
                </td>
                <td>
                  <p>THP</p>
                  <p>TXH</p>
                </td>
              </tr>
              <tr>
                <td>
                  曲妥珠单抗治疗失败
                </td>
                <td>
                  吡咯替尼+卡培他滨
                </td>
              </tr>
            </table>
          </div>
          注：<popover title="THP" :center="THPTips" :list="list" />：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概3.5万元左右，
          <popover title="TXH" :center="TXHTips" :list="list" />：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概2.7万元左右。
        </div>
        <div class="center" v-if="answer=='ABB'">
          <div class="frist-title">早期治疗方案推荐</div>
          <div class="line-height">未行辅助治疗的治疗方案选择</div>
          （1）腋窝淋巴结阳性，推荐
          <popover title="AC-THP" :center="ACTHPTips" :list="list" />、
          <popover title="TCbHP" :center="TCbHPTips" :list="list" />，
          <popover title="AC-THP" :center="ACTHPTips" :list="list" />方案大概四个疗程，
          每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛加双靶向用药，
          一般每疗程21天，使用大概一年时间，每疗程总花费大概在3.3万元左右；<popover title="TCbHP" :center="TCbHPTips" :list="list" />方案大概六个疗程，
          每个疗程21天，每疗程总花费大概在3.3万元左右。<br>
          （2）腋窝淋巴结阳性，伴以下危险因素之一：a.T>2cm；b.其他危险因素，如ER阴性，推荐<popover title="AC-TH" :center="ACTHTips" :list="list" />，
          <popover title="TCbH" :center="TCbHTips" :list="list" />，其中
          <popover title="AC-TH" :center="ACTHTips" :list="list" />方案蒽环类+环磷酰胺大概四个疗程，每个疗程
          21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛+曲妥珠单抗，
          其中曲妥珠单抗要连续使用一年，每疗程总花费大概在2.7万元左右；<popover title="TCbH" :center="TCbHTips" :list="list" />
          方案一般六个疗程，每疗程21天，其中曲妥珠单抗要连续使用一年。 每疗程总花费大概在2.7万元左右。<br>
          （3）腋窝淋巴结阳性且T<2cm，推荐<popover title="TC+H" :center="TCbTips" :list="list" />，方案一般四个疗程，
          每疗程21天，其中曲 妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。<br>
          （4）激素受体阳性，且无需化疗或不能耐受化疗者，二级推荐曲妥珠单抗+内分泌治疗。。<br>
          <div class="line-height">使用过新辅助化疗的：</div>
          <div class="tab-box" >
            <table>
              <tr>
                <th>术前抗HER-2治疗仅使用曲妥珠单抗</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  病理学完全缓解（pCR）
                </td>
                <td>曲妥珠单抗</td>
              </tr>
              <tr>
                <td>
                  病理学未完全缓解（non pCR）
                </td>
                <td>
                  <p>HP</p>
                  <p>T-DM1</p>
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <th>术前抗HER-2治疗使用曲妥珠单抗联合帕妥珠单抗</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  病理学完全缓解（pCR）
                </td>
                <td>HP</td>
              </tr>
              <tr>
                <td>
                  病理学未完全缓解（non pCR）
                </td>
                <td>
                  T-DM1（恩美曲妥珠单抗）
                </td>
              </tr>
            </table>
          </div>
          <div>化疗方案完成后进行内分泌治疗</div>
          <div class="mid" @click="show= !show"><span class="font-bule">绝经前点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show">
            <div class="tab-title">初始治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>低复发风险患者（全满足）</p>
                  <p>1、淋巴结阴性</p>
                  <p>2、G1</p>
                  <p>3、T≤2cm</p>
                  <p>4、Ki-67低表达（≤30%）</p>
                </td>
                <td>TAM5年</td>
              </tr>
              <tr>
                <td>
                  <p>满足以下任意条件者</p>
                  <p>1、G2或G3</p>
                  <p>2、淋巴结阳性</p>
                  <p>3、T>2cm</p>
                </td>
                <td>
                  OFS+TAM5年
                </td>
              </tr>
              <tr>
                <td>淋巴结阳性>=4个患者</td>
                <td>
                  OFS+AI5年
                </td>
              </tr>
            </table>
            <div class="tab-title">延长治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  完成TAM5年初始治疗且耐受良好患者
                </td>
                <td>
                  <p>1、未绝经患者：延长TAM治疗至10年</p>
                  <p>2、绝经患者：序贯AI治疗5年</p>
                </td>
              </tr>
              <tr>
                <td>
                  完成OFS+TAM5年初始治疗且耐受良好患者
                </td>
                <td>
                  绝经患者：序贯AI治疗
                </td>
              </tr>
              <tr>
                <td>完成OFS+AI5年初始治疗且耐受良好患者</td>
                <td>
                  绝经患者：AI治疗
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show=false">收起<van-icon name="arrow-up" /></div>
          </div>
          <div class="mid" @click="show1= true"><span class="font-bule">绝经后点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show1">
            <div class="tab-title">初始治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>全部</td>
                <td>
                  <p>1、AI5年</p>
                  <p>2、初始使用TAM治疗，可换用AI5年</p>
                </td>
              </tr>
            </table>
            <div class="tab-title">延长治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  完成AI5年初始治疗且耐受良好低危患者
                </td>
                <td>
                  可停药
                </td>
              </tr>
              <tr>
                <td>
                  <p>完成AI5年初始治疗且耐受良好患者，存在以下情况：</p>
                  <p>（1）淋巴结阳性</p>
                  <p>（2）G3</p>
                  <p>（3）其他需要行辅助化疗的危险因素，如KI-67＞30%</p>
                </td>
                <td>
                  继续AI治疗
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show1=!show1">收起<van-icon name="arrow-up" /></div>
          </div>
          <div class="frist-title">晚期治疗方案推荐</div>
          <div class="tab-box" >
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>1、未用过曲妥珠单抗</p>
                  <p>2、曾用曲妥珠单抗且符合再使用标准</p>
                </td>
                <td>
                  <p>THP</p>
                  <p>TXH</p>
                </td>
              </tr>
              <tr>
                <td>
                  曲妥珠单抗治疗失败
                </td>
                <td>
                  吡咯替尼+卡培他滨
                </td>
              </tr>
            </table>
          </div>
          注：<popover title="THP" :center="THPTips" :list="list" />：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概3.5万元左右，
          <popover title="TXH" :center="TXHTips" :list="list" />：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概2.7万元左右。
          <div class="mid" @click="show2=!show2"><span class="font-bule">化疗后开始内分泌治疗点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show2">
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>未经内分泌治疗</td>
                <td>AI+CDK4/6抑制剂</td>
              </tr>
              <tr>
                <td>TAM治疗失败</td>
                <td>
                  <p>AI+CDK4/6抑制剂</p>
                  <p>AI+HDAC抑制剂</p>
                  <p>氟维司群+CDK4/6抑制剂</p>
                </td>
              </tr>
              <tr>
                <td>非甾体类AI治疗失败</td>
                <td>
                  <p>甾体类AI+HDAC抑制剂</p>
                  <p>氟维司群+CDK4/6抑制剂</p>
                </td>
              </tr>
              <tr>
                <td>甾体类AI治疗失败</td>
                <td>氟维司群+CDK4/6抑制剂</td>
              </tr>
            </table>
            <div class="show-btn" @click="show2=false">收起<van-icon name="arrow-up" /></div>
          </div>
        </div>
        <div class="center" v-if="answer=='BBB'">
          <div class="frist-title">早期患者治疗方案推荐</div>
          <div class="line-height">未行辅助治疗的治疗方案选择</div>
          （1）腋窝淋巴结阳性，推荐
          <popover title="AC-THP" :center="ACTHPTips" :list="list" />、
          <popover title="TCbHP" :center="TCbHPTips" :list="list" />，
          <popover title="AC-THP" :center="ACTHPTips" :list="list" />方案大概四个疗程，
          每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛加双靶向用药，一般每疗程21天，使用大概一年时间，每疗程总花费大概在3.3万元左右；<popover title="TCbHP" :center="TCbHPTips" :list="list" />方案大概六个疗程，
          每个疗程21天，每疗程总花费大概在3.3万元左右。<br>
          （2）腋窝淋巴结阳性，伴以下危险因素之一：a.T>2cm；b.其他危险因素，如ER阴
          性，推荐<popover title="AC-TH" :center="ACTHTips" :list="list" />，
          <popover title="TCbH" :center="TCbHTips" :list="list" />，其中
          <popover title="AC-TH" :center="ACTHTips" :list="list" />方案蒽环类+环磷酰胺大概四个疗程，每个疗程
          21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛+曲妥珠单抗，
          其中曲妥珠单抗要连续使用一年，每疗程总花费大概在2.7万元左右；<popover title="TCbH" :center="TCbHTips" :list="list" />
          方案一般六个疗程，每疗程21天，其中曲妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。<br>
          （3）腋窝淋巴结阳性且T<2cm，推荐<popover title="TC+H" :center="TCbTips" :list="list" />，方案一般四个疗程，
          每疗程21天，其中曲 妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。<br>
          （4）激素受体阳性，且无需化疗或不能耐受化疗者，二级推荐曲妥珠单抗+内分泌治疗。。<br>
          <div class="line-height">使用过新辅助化疗的：</div>
          <div class="tab-box" >
            <table>
              <tr>
                <th>术前抗HER-2治疗仅使用曲妥珠单抗</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  病理学完全缓解（pCR）
                </td>
                <td>曲妥珠单抗</td>
              </tr>
              <tr>
                <td>
                  病理学未完全缓解（non pCR）
                </td>
                <td>
                  <p>HP</p>
                  <p>T-DM1</p>
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <th>术前抗HER-2治疗使用曲妥珠单抗联合帕妥珠单抗</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  病理学完全缓解（pCR）
                </td>
                <td>HP</td>
              </tr>
              <tr>
                <td>
                  病理学未完全缓解（non pCR）
                </td>
                <td>
                  T-DM1（恩美曲妥珠单抗）
                </td>
              </tr>
            </table>
          </div>
          <div>化疗方案完成后进行内分泌治疗</div>
          <div class="mid" @click="show= !show"><span class="font-bule">绝经前点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show">
            <div class="tab-title">初始治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>低复发风险患者（全满足）</p>
                  <p>1、淋巴结阴性</p>
                  <p>2、G1</p>
                  <p>3、T≤2cm</p>
                  <p>4、Ki-67低表达（≤30%）</p>
                </td>
                <td>TAM5年</td>
              </tr>
              <tr>
                <td>
                  <p>满足以下任意条件者</p>
                  <p>1、G2或G3</p>
                  <p>2、淋巴结阳性</p>
                  <p>3、T>2cm</p>
                </td>
                <td>
                  OFS+TAM5年
                </td>
              </tr>
              <tr>
                <td>淋巴结阳性>=4个患者</td>
                <td>
                  OFS+AI5年
                </td>
              </tr>
            </table>
            <div class="tab-title">延长治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  完成TAM5年初始治疗且耐受良好患者
                </td>
                <td>
                  <p>1、未绝经患者：延长TAM治疗至10年</p>
                  <p>2、绝经患者：序贯AI治疗5年</p>
                </td>
              </tr>
              <tr>
                <td>
                  完成OFS+TAM5年初始治疗且耐受良好患者
                </td>
                <td>
                  绝经患者：序贯AI治疗
                </td>
              </tr>
              <tr>
                <td>完成OFS+AI5年初始治疗且耐受良好患者</td>
                <td>
                  绝经患者：AI治疗
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show=false">收起<van-icon name="arrow-up" /></div>
          </div>
          <div class="mid" @click="show1= !show1"><span class="font-bule">绝经后点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show1">
            <div class="tab-title">初始治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>全部</td>
                <td>
                  <p>1、AI5年</p>
                  <p>2、初始使用TAM治疗，可换用AI5年</p>
                </td>
              </tr>
            </table>
            <div class="tab-title">延长治疗：</div>
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  完成AI5年初始治疗且耐受良好低危患者
                </td>
                <td>
                  可停药
                </td>
              </tr>
              <tr>
                <td>
                  <p>完成AI5年初始治疗且耐受良好患者，存在以下情况：</p>
                  <p>（1）淋巴结阳性</p>
                  <p>（2）G3</p>
                  <p>（3）其他需要行辅助化疗的危险因素，如KI-67＞30%</p>
                </td>
                <td>
                  继续AI治疗
                </td>
              </tr>
            </table>
            <div class="show-btn" @click="show1=false">收起<van-icon name="arrow-up" /></div>
          </div>
          <div  class="frist-title">晚期患者治疗方案推荐</div>
          <div class="tab-box" >
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>
                  <p>1、未用过曲妥珠单抗</p>
                  <p>2、曾用曲妥珠单抗且符合再使用标准</p>
                </td>
                <td>
                  <p>THP</p>
                  <p>TXH</p>
                </td>
              </tr>
              <tr>
                <td>
                  曲妥珠单抗治疗失败
                </td>
                <td>
                  吡咯替尼+卡培他滨
                </td>
              </tr>
            </table>
          </div>
          注：<popover title="THP" :center="THPTips" :list="list" />：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概3.5万元左右，
          <popover title="TXH" :center="TXHTips" :list="list" />：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概2.7万元左右。
          <div class="mid" @click="show2= !show2"><span class="font-bule">化疗后开始内分泌治疗点击查看详情>></span>（方案来源指南，仅供参考，无临床指导意义）</div>
          <div class="tab-box" v-if="show2">
            <table>
              <tr>
                <th>患者情况</th>
                <th>一级推荐</th>
              </tr>
              <tr>
                <td>未经内分泌治疗</td>
                <td>AI+CDK4/6抑制剂</td>
              </tr>
              <tr>
                <td>TAM治疗失败</td>
                <td>
                  <p>AI+CDK4/6抑制剂</p>
                  <p>AI+HDAC抑制剂</p>
                  <p>氟维司群+CDK4/6抑制剂</p>
                </td>
              </tr>
              <tr>
                <td>非甾体类AI治疗失败</td>
                <td>
                  <p>甾体类AI+HDAC抑制剂</p>
                  <p>氟维司群+CDK4/6抑制剂</p>
                </td>
              </tr>
              <tr>
                <td>甾体类AI治疗失败</td>
                <td>氟维司群+CDK4/6抑制剂</td>
              </tr>
            </table>
            <div class="show-btn" @click="show2=false">收起<van-icon name="arrow-up" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {RadioGroup, Radio, Form, Field, Button, Icon, Toast} from 'vant';
import popover from './popover'
Vue.use(Form);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Icon);
export default {
  name: 'knowledge',
  data() {
    return {
      operation:"",
      her2:"",
      hormone:"",
      show:false,
      show1:false,
      show2:false,
      answer:'',
      list:[],
      gfxTips:['高风险患者（任一条件满足）：','1、腋窝淋巴结阳性>=4个','2、淋巴结阳性1-3，并伴有其他复发风险','3、三阴性乳腺癌'],
      ACTTips:'蒽环类+环磷酰胺序贯多西他赛',
      ddACddtTips:'密集型蒽环类+环磷酰胺序贯密集型紫杉醇',
      dfxTips:['低复发风险患者，但伴有以下危险因素之一：','1、淋巴结阳性1-3（Luminal A）','2、ki-67高表达（>30%）','3、T>2cm','4、年龄小于35岁'],
      ACTips:"蒽环类+环磷酰胺",
      TCTips:"多西他赛+环磷酰胺",
      TXTips:'紫衫类+卡培他滨',
      GTTips:'吉西他滨+紫杉醇',
      TPTips:'紫衫联合铂类',
      NPTips:"长春瑞滨联合铂类",
      GPTips:'吉西他滨+联合铂类',
      NXTips:'长春瑞滨+卡培他滨',
      ACTHPTips:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗+帕妥珠单抗",
      TCbHPTips:"多西他赛+卡铂+曲妥珠单抗+帕妥珠单抗",
      ACTHTips:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗",
      TCbHTips:'多西他赛+卡铂+曲妥珠单抗',
      TCbTips:"多西他赛+环磷酰胺+曲妥珠单抗",
      THPTips:"多西他赛+曲妥珠单抗+帕妥珠单抗",
      TXHTips:"紫衫类+卡培他滨+曲妥珠单抗"
    }
  },
   components: {
     popover
   },
  created() {

  },
  mounted() {

  },
  methods: {
    onSubmit(){
      if(this.operation ==''){
        Toast('请选择术式');
        return
      }
      if(this.her2 == ''){
        Toast('请选择Her2');
        return
      }
      if(this.hormone == ''){
        Toast('请选择激素受体');
        return
      }
      this.answer = this.operation+this.her2+this.hormone
    },
    reset(){
      this.answer =''
    },
    onReset(){
      this.operation ='',
      this.her2 = '',
      this.hormone = '',
      this.answer =''
    }
  },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.knowledge{
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  overflow-y: scroll;
  padding: .3rem;
  .top{
    .wenti-box{
      background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .4rem .3rem;
    }
  }
  .wenti-img{
    width: 100%;
    height: .34rem;
    img{
      width:.4rem;
      height: .34rem;
    }
  }
  .bottom{
    margin-top: .3rem;
    .knowledge-box{
      background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .4rem .3rem;
      .title{
        padding-bottom: .3rem;
        text-align: center;
        font-weight: 500;
        color: #1675FF;
        font-size: .28rem;
        border-bottom: 1px solid rgba(146, 157, 163, .08);
      }
      .center{
        font-size: .26rem;
        line-height: .5rem;
        margin-top: .4rem;
      }
    }
  }
}
.mid{
  font-weight: 500;
  color: #C4C4C4;
  margin: .3rem 0;
}
.tab-box{
  table{
    width: 100%;
    border-collapse:collapse;
    margin-bottom:.2rem;
  }
  th{
    background: #F1F8FF;
    //line-height: .72rem;
    font-size: .24rem;
    color: #131313;
    padding: .2rem 0;
  }
  td{
    font-size: .24rem;
    padding: .28rem;
    border:1px solid rgba(146, 157, 163, .04);
    line-height: .4rem;
    p{
      line-height: .4rem;
    }
  }
}
.tab-title{
  font-size: .24rem;
  line-height: .6rem;
}
.show-btn{
  color: #1675FF;
  font-size: .24rem;
  text-align: center;
  margin-top: .3rem;
}
.frist-title{
  font-size: .28rem;
  font-weight: 500;
}
.font-bule{
  color: #1675FF;
}
.line-height{
  line-height: .7rem;
  font-weight: 500;
}
</style>

