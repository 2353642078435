var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popover",
    {
      attrs: { trigger: "click", placement: "bottom-start" },
      scopedSlots: _vm._u([
        {
          key: "reference",
          fn: function() {
            return [
              _c("span", { staticClass: "font-bule" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopover,
        callback: function($$v) {
          _vm.showPopover = $$v
        },
        expression: "showPopover"
      }
    },
    [
      _c("div", { staticClass: "tips" }, [
        _vm._v(" " + _vm._s(_vm.center) + " ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }