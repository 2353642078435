var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "knowledge" }, [
    _c("div", { staticClass: "top" }, [
      _vm._m(0),
      _c("div", { staticClass: "wenti-box" }, [
        _c("div", [_vm._v("选择选项")]),
        _c(
          "div",
          [
            _c(
              "van-form",
              [
                _c("van-field", {
                  attrs: { name: "radio", label: "术式:" },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function() {
                        return [
                          _c(
                            "van-radio-group",
                            {
                              attrs: { direction: "horizontal" },
                              on: { change: _vm.reset },
                              model: {
                                value: _vm.operation,
                                callback: function($$v) {
                                  _vm.operation = $$v
                                },
                                expression: "operation"
                              }
                            },
                            [
                              _c("van-radio", { attrs: { name: "A" } }, [
                                _vm._v("A.保乳")
                              ]),
                              _c("van-radio", { attrs: { name: "B" } }, [
                                _vm._v("B.全切")
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("van-field", {
                  attrs: { name: "radio", label: "Her2:" },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function() {
                        return [
                          _c(
                            "van-radio-group",
                            {
                              attrs: { direction: "horizontal" },
                              on: { change: _vm.reset },
                              model: {
                                value: _vm.her2,
                                callback: function($$v) {
                                  _vm.her2 = $$v
                                },
                                expression: "her2"
                              }
                            },
                            [
                              _c("van-radio", { attrs: { name: "A" } }, [
                                _vm._v("A.阴性")
                              ]),
                              _c("van-radio", { attrs: { name: "B" } }, [
                                _vm._v("B.阳性")
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("van-field", {
                  attrs: { name: "radio", label: "激素受体:" },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function() {
                        return [
                          _c(
                            "van-radio-group",
                            {
                              attrs: { direction: "horizontal" },
                              on: { change: _vm.reset },
                              model: {
                                value: _vm.hormone,
                                callback: function($$v) {
                                  _vm.hormone = $$v
                                },
                                expression: "hormone"
                              }
                            },
                            [
                              _c("van-radio", { attrs: { name: "A" } }, [
                                _vm._v("A.阴性")
                              ]),
                              _c("van-radio", { attrs: { name: "B" } }, [
                                _vm._v("B.阳性")
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c(
                  "div",
                  { staticStyle: { margin: "16px", display: "flex" } },
                  [
                    _c(
                      "van-button",
                      {
                        staticStyle: { "margin-right": ".2rem" },
                        attrs: {
                          round: "",
                          plain: "",
                          block: "",
                          type: "info"
                        },
                        on: { click: _vm.onReset }
                      },
                      [_vm._v("清空")]
                    ),
                    _c(
                      "van-button",
                      {
                        staticStyle: { "margin-left": ".2rem" },
                        attrs: {
                          round: "",
                          block: "",
                          type: "info",
                          "native-type": "submit"
                        },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v("提交")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _vm.answer != ""
      ? _c("div", { staticClass: "bottom" }, [
          _vm._m(1),
          _c("div", { staticClass: "knowledge-box" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", [_vm._v("答案展示")]),
              _vm.answer == "AAA"
                ? _c("div", [_vm._v("（保乳术后、Her2阴性、激素受体阴性）")])
                : _vm._e(),
              _vm.answer == "BAA"
                ? _c("div", [_vm._v("（全切术后，Her2阴性，激素受体阴性）")])
                : _vm._e(),
              _vm.answer == "AAB"
                ? _c("div", [_vm._v("（保乳术后，Her2阴性，激素受体阳性）")])
                : _vm._e(),
              _vm.answer == "BAB"
                ? _c("div", [_vm._v("（全切术后，Her2阴性，激素受体阳性）")])
                : _vm._e(),
              _vm.answer == "BBA"
                ? _c("div", [_vm._v("（全切术后，Her2阳性，激素受体阴性）")])
                : _vm._e(),
              _vm.answer == "ABA"
                ? _c("div", [_vm._v("（保乳术后，Her2阳性，激素受体阴性）")])
                : _vm._e(),
              _vm.answer == "ABB"
                ? _c("div", [_vm._v("（保乳术后，Her2阳性，激素受体阳性）")])
                : _vm._e(),
              _vm.answer == "BBB"
                ? _c("div", [_vm._v("（全切术后，Her2阳性，激素受体阳性）")])
                : _vm._e()
            ]),
            _vm.answer == "AAA"
              ? _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _vm._v(" 早期 "),
                    _c("popover", {
                      attrs: {
                        title: "高风险患者",
                        list: _vm.gfxTips,
                        center: ""
                      }
                    }),
                    _vm._v(" 的治疗方案推荐使用 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-T",
                        center: _vm.ACTTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("、"),
                    _c("popover", {
                      attrs: {
                        title: "ddAC-ddT",
                        center: _vm.ddACddtTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(" ，选择 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-T",
                        center: _vm.ACTTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      " ，每疗程21天， 大概四个疗程，每疗程总花费大概3万元左右，选择 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "ddAC-ddT",
                        center: _vm.ddACddtTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案，每疗程14天，大概四个疗程， 每疗程总花费大概2.5万元左右。 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "低风险患者",
                        list: _vm.dfxTips,
                        center: ""
                      }
                    }),
                    _vm._v(" 推荐 "),
                    _c("popover", {
                      attrs: { title: "AC", list: _vm.list, center: _vm.ACTips }
                    }),
                    _vm._v(" 或者"),
                    _c("popover", {
                      attrs: { title: "TC", list: _vm.list, center: _vm.TCTips }
                    }),
                    _vm._v("方案， "),
                    _c("popover", {
                      attrs: { title: "AC", list: _vm.list, center: _vm.ACTips }
                    }),
                    _vm._v(
                      "方案每疗程21天，大概四 个疗程，每疗程总花费大概5000元左右。"
                    ),
                    _c("popover", {
                      attrs: { title: "TC", list: _vm.list, center: _vm.TCTips }
                    }),
                    _vm._v(
                      "方案每疗程21天，大概四个疗程，每疗程总花费大概 2000元左右。 "
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show = !_vm.show
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("放疗方案点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show
                      ? _c("div", { staticClass: "tab-box" }, [
                          _vm._m(2),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(
                      " 晚期患者的治疗方案分两种情况，蒽环类治疗失败的推荐单药紫杉类 （白蛋白紫杉醇、多西他赛、紫杉醇）联合化疗（ "
                    ),
                    _c("popover", {
                      attrs: { title: "TX", center: _vm.TXTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "GT", center: _vm.GTTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "TP", center: _vm.TPTips, list: _vm.list }
                    }),
                    _vm._v(
                      "）， 此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱；蒽环类和 紫衫类治疗失败的患者推荐是用单药化疗（卡培他滨、长春瑞滨、吉 西他滨）联合 "
                    ),
                    _c("popover", {
                      attrs: { title: "NP", center: _vm.NPTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "GP", center: _vm.GPTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "NX", center: _vm.NXTips, list: _vm.list }
                    }),
                    _vm._v(
                      "化疗，此种情况大概每疗程大概花费千余元， 具体疗程谨遵医嘱。 "
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.answer == "BAA"
              ? _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _vm._v(" 早期"),
                    _c("popover", {
                      attrs: {
                        title: "高风险患者",
                        list: _vm.gfxTips,
                        center: ""
                      }
                    }),
                    _vm._v("的治疗方案推荐使用 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-T",
                        center: _vm.ACTTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: {
                        title: "ddAC-ddT",
                        center: _vm.ddACddtTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， 选择"),
                    _c("popover", {
                      attrs: {
                        title: "AC-T",
                        center: _vm.ACTTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "， 每疗程21天，大概四个疗程，每疗程总花费大概3万元左右，选择 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "ddAC-ddT",
                        center: _vm.ddACddtTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案， 每疗程14天，大概四个疗程，每疗程总花费大概2.5万元左右。 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "低风险患者",
                        list: _vm.dfxTips,
                        center: ""
                      }
                    }),
                    _vm._v("推荐 "),
                    _c("popover", {
                      attrs: { title: "AC", list: _vm.list, center: _vm.ACTips }
                    }),
                    _vm._v(" 或者 "),
                    _c("popover", {
                      attrs: { title: "TC", list: _vm.list, center: _vm.TCTips }
                    }),
                    _vm._v("方案， "),
                    _c("popover", {
                      attrs: { title: "AC", list: _vm.list, center: _vm.ACTips }
                    }),
                    _vm._v(
                      "方案每疗程21天，大概四个疗程，每疗程总花费大概5000元左右。 "
                    ),
                    _c("popover", {
                      attrs: { title: "TC", list: _vm.list, center: _vm.TCTips }
                    }),
                    _vm._v(
                      "方案每疗程21天，大概四个疗程，每疗程总花费大概2000元左右。 "
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show = !_vm.show
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("放疗方案点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show
                      ? _c("div", { staticClass: "tab-box" }, [
                          _vm._m(3),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(
                      " 晚期患者的治疗方案分两种情况，蒽环类治疗失败的推荐单药紫杉类 （白蛋白紫杉醇、多西他赛、紫杉醇）联合化疗（ "
                    ),
                    _c("popover", {
                      attrs: { title: "TX", center: _vm.TXTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "GT", center: _vm.GTTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "TP", center: _vm.TPTips, list: _vm.list }
                    }),
                    _vm._v(
                      "）， 此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱；蒽环类和 紫衫类治疗失败的患者推荐是用单药化疗（卡培他滨、长春瑞滨、吉西 他滨）联合 "
                    ),
                    _c("popover", {
                      attrs: { title: "NP", center: _vm.NPTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "GP", center: _vm.GPTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "NX", center: _vm.NXTips, list: _vm.list }
                    }),
                    _vm._v(
                      "化疗，此种情况大概每疗程大概花费千余元， 具体疗程谨遵医嘱。 "
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.answer == "AAB"
              ? _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _vm._v(" 早期 "),
                    _c("popover", {
                      attrs: {
                        title: "高风险患者",
                        list: _vm.gfxTips,
                        center: ""
                      }
                    }),
                    _vm._v(" 的治疗方案推荐使用 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-T",
                        center: _vm.ACTTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: {
                        title: "ddAC-ddT",
                        center: _vm.ddACddtTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(" ，选择 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-T",
                        center: _vm.ACTTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      " ，每疗程21天，大概四个疗程，每疗程总花费大概3万元左右，选择 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "ddAC-ddT",
                        center: _vm.ddACddtTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案，每疗程14天，大概四个疗程， 每疗程总花费2.5万元左右。 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "低风险患者",
                        list: _vm.dfxTips,
                        center: ""
                      }
                    }),
                    _vm._v(" 推荐 "),
                    _c("popover", {
                      attrs: { title: "AC", list: _vm.list, center: _vm.ACTips }
                    }),
                    _vm._v(" 或者"),
                    _c("popover", {
                      attrs: { title: "TC", list: _vm.list, center: _vm.TCTips }
                    }),
                    _vm._v("方案， "),
                    _c("popover", {
                      attrs: { title: "AC", list: _vm.list, center: _vm.ACTips }
                    }),
                    _vm._v(
                      "方案每疗程21天，大概四 个疗程，每疗程总花费大概5000元左右。"
                    ),
                    _c("popover", {
                      attrs: { title: "TC", list: _vm.list, center: _vm.TCTips }
                    }),
                    _vm._v(
                      "方案每疗程21天，大概四个疗程，每疗程总花费大概2000元左右。 "
                    ),
                    _c("div", { staticStyle: { "margin-top": ".2rem" } }, [
                      _vm._v("疗程完成后进行内分泌治疗")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show = !_vm.show
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("绝经前方案点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show
                      ? _c("div", { staticClass: "tab-box" }, [
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("初始治疗：")
                          ]),
                          _vm._m(4),
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("延长治疗")
                          ]),
                          _vm._m(5),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show1 = !_vm.show1
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("绝经后方案点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show1
                      ? _c("div", { staticClass: "tab-box" }, [
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("初始治疗：")
                          ]),
                          _vm._m(6),
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("延长治疗")
                          ]),
                          _vm._m(7),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show1 = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(
                      " 晚期患者的治疗方案分两种情况，蒽环类治疗失败的推荐单药紫杉类 （白蛋白紫杉醇、多西他赛、紫杉醇）联合化疗（ "
                    ),
                    _c("popover", {
                      attrs: { title: "TX", center: _vm.TXTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "GT", center: _vm.GTTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "TP", center: _vm.TPTips, list: _vm.list }
                    }),
                    _vm._v(
                      "）， 此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱；蒽环类和 紫衫类治疗失败的患者推荐是用单药化疗（卡培他滨、长春瑞滨、吉 西他滨）联合 "
                    ),
                    _c("popover", {
                      attrs: { title: "NP", center: _vm.NPTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "GP", center: _vm.GPTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "NX", center: _vm.NXTips, list: _vm.list }
                    }),
                    _vm._v(
                      "化疗，此种情况大概每疗程大概花费千余 元，具体疗程谨遵医嘱。 "
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show2 = !_vm.show2
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("化疗后开始内分泌治疗点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show2
                      ? _c("div", { staticClass: "tab-box" }, [
                          _vm._m(8),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show2 = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.answer == "BAB"
              ? _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _vm._v(" 早期"),
                    _c("popover", {
                      attrs: {
                        title: "高风险患者",
                        list: _vm.gfxTips,
                        center: ""
                      }
                    }),
                    _vm._v(" 的治疗方案推荐使用 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-T",
                        center: _vm.ACTTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: {
                        title: "ddAC-ddT",
                        center: _vm.ddACddtTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， 选择 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-T",
                        center: _vm.ACTTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      " ，每疗程21天，大概四个疗程，每疗程总花费大概3万元左右，选择 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "ddAC-ddT",
                        center: _vm.ddACddtTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案， 每疗程14天，大概四个疗程，每疗程总花费大概2.5万元左右。 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "低风险患者",
                        list: _vm.dfxTips,
                        center: ""
                      }
                    }),
                    _vm._v("推荐 "),
                    _c("popover", {
                      attrs: { title: "AC", list: _vm.list, center: _vm.ACTips }
                    }),
                    _vm._v("或者 "),
                    _c("popover", {
                      attrs: { title: "TC", list: _vm.list, center: _vm.TCTips }
                    }),
                    _vm._v("方案， "),
                    _c("popover", {
                      attrs: { title: "AC", list: _vm.list, center: _vm.ACTips }
                    }),
                    _vm._v(
                      "方案每疗程21天，大概四个疗程，每疗程总花费大概5000元左右。 "
                    ),
                    _c("popover", {
                      attrs: { title: "TC", list: _vm.list, center: _vm.TCTips }
                    }),
                    _vm._v(
                      " 方案每疗程21天，大概四个疗程，每疗程总花费大概2000元左右。 "
                    ),
                    _c("div", { staticStyle: { "margin-top": ".2rem" } }, [
                      _vm._v("化疗方案完成后进行内分泌治疗")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show = !_vm.show
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("绝经前方案点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show
                      ? _c("div", { staticClass: "tab-box" }, [
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("初始治疗：")
                          ]),
                          _vm._m(9),
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("延长治疗")
                          ]),
                          _vm._m(10),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show1 = !_vm.show1
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("绝经后方案点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show1
                      ? _c("div", { staticClass: "tab-box" }, [
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("初始治疗：")
                          ]),
                          _vm._m(11),
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("延长治疗")
                          ]),
                          _vm._m(12),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show1 = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(
                      " 晚期患者的治疗方案分两种情况，蒽环类治疗失败的推荐单药紫杉类 （白蛋白紫杉醇、多西他赛、紫杉醇）联合化疗（ "
                    ),
                    _c("popover", {
                      attrs: { title: "TX", center: _vm.TXTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "GT", center: _vm.GTTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "TP", center: _vm.TPTips, list: _vm.list }
                    }),
                    _vm._v(
                      "）， 此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱；蒽环类和 紫衫类治疗失败的患者推荐是用单药化疗（卡培他滨、长春瑞滨、吉 西他滨）联合 "
                    ),
                    _c("popover", {
                      attrs: { title: "NP", center: _vm.NPTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "GP", center: _vm.GPTips, list: _vm.list }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: { title: "NX", center: _vm.NXTips, list: _vm.list }
                    }),
                    _vm._v(
                      "化疗，此种情况大概每疗程大概花费千余元，具体疗程谨遵医嘱。 "
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show2 = !_vm.show2
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("化疗后开始内分泌治疗点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show2
                      ? _c("div", { staticClass: "tab-box" }, [
                          _vm._m(13),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show2 = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.answer == "BBA"
              ? _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _c("div", { staticClass: "frist-title" }, [
                      _vm._v("早期治疗方案推荐")
                    ]),
                    _c("div", { staticClass: "line-height" }, [
                      _vm._v("未行辅助治疗的治疗方案选择")
                    ]),
                    _vm._v(" （1）腋窝淋巴结阳性，推荐 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-THP",
                        center: _vm.ACTHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: {
                        title: "TCbHP",
                        center: _vm.TCbHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， "),
                    _c("popover", {
                      attrs: {
                        title: "AC-THP",
                        center: _vm.ACTHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案大概四个疗程， 每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他 赛加双靶向用药，一般每疗程21天，使用大概一年时间，每疗程总花费大概在3.3万元左右； "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TCbHP",
                        center: _vm.TCbHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      " 方案大概六个疗程，每个疗程21天，每疗程总花费大概在3.3万元左右。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （2）腋窝淋巴结阳性，伴以下危险因素之一：a.T>2cm；b.其他危险因素， 如ER阴性，推荐 "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "AC-TH",
                        center: _vm.ACTHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， "),
                    _c("popover", {
                      attrs: {
                        title: "TCbH",
                        center: _vm.TCbHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("，其中 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-TH",
                        center: _vm.ACTHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案蒽环类+环磷酰胺大概四个疗 程，每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西 他赛+曲妥珠单抗，其中曲妥珠单抗要连续使用一年，每疗程总花费大概在2.7万元左右； "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TCbH",
                        center: _vm.TCbHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案一般六个疗程，每疗程21天，其中曲妥珠单抗要连续使用一 年。每疗程总花费大概在2.7万元左右。"
                    ),
                    _c("br"),
                    _vm._v(" （3）腋窝淋巴结阳性且T<2cm，推荐 "),
                    _c("popover", {
                      attrs: {
                        title: "TC+H",
                        center: _vm.TCbTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "，方案一般四个疗程，每疗程21天， 其中曲妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （4）激素受体阳性，且无需化疗或不能耐受化疗者，二级推荐曲妥珠单抗+内分泌治疗。 "
                    ),
                    _c("div", { staticClass: "line-height" }, [
                      _vm._v("使用过新辅助化疗的：")
                    ]),
                    _vm._m(14),
                    _c("div", { staticClass: "frist-title" }, [
                      _vm._v("晚期治疗方案推荐")
                    ]),
                    _vm._m(15),
                    _vm._v(" 注："),
                    _c("popover", {
                      attrs: {
                        title: "THP",
                        center: _vm.THPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概3.5万元左右， "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TXH",
                        center: _vm.TXHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概2.7万元左右。 "
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.answer == "ABA"
              ? _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _c("div", { staticClass: "frist-title" }, [
                      _vm._v("早期治疗方案推荐")
                    ]),
                    _c("div", { staticClass: "line-height" }, [
                      _vm._v("未行辅助治疗的治疗方案选择")
                    ]),
                    _vm._v(" （1）腋窝淋巴结阳性，推荐 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-THP",
                        center: _vm.ACTHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: {
                        title: "TCbHP",
                        center: _vm.TCbHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， "),
                    _c("popover", {
                      attrs: {
                        title: "AC-THP",
                        center: _vm.ACTHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案大概四个疗程， 每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛加双靶向用药， 一般每疗程21天，使用大概一年时间，每疗程总花费大概在3.3万元左右； "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TCbHP",
                        center: _vm.TCbHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案大概六个疗程， 每个疗程21天，每疗程总花费大概在3.3万元左右。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （2）腋窝淋巴结阳性，伴以下危险因素之一：a.T>2cm；b.其他危险因素，如ER阴 性，推荐"
                    ),
                    _c("popover", {
                      attrs: {
                        title: "AC-TH",
                        center: _vm.ACTHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， "),
                    _c("popover", {
                      attrs: {
                        title: "TCbH",
                        center: _vm.TCbHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("，其中 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-TH",
                        center: _vm.ACTHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案蒽环类+环磷酰胺大概四个疗程，每个疗程 21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛+曲妥珠单抗， 其中曲妥珠单抗要连续使用一年，每疗程总花费大概在2.7万元左右；"
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TCbH",
                        center: _vm.TCbHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      " 方案一般六个疗程，每疗程21天，其中曲妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。"
                    ),
                    _c("br"),
                    _vm._v(" （3）腋窝淋巴结阳性且T<2cm，推荐"),
                    _c("popover", {
                      attrs: {
                        title: "TC+H",
                        center: _vm.TCbTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "，方案一般四个疗程， 每疗程21天，其中曲 妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （4）激素受体阳性，且无需化疗或不能耐受化疗者，二级推荐曲妥珠单抗+内分泌治疗。"
                    ),
                    _c("br"),
                    _c("div", { staticClass: "line-height" }, [
                      _vm._v("使用过新辅助化疗的：")
                    ]),
                    _vm._m(16),
                    _c("div", [_vm._v("晚期治疗方案推荐")]),
                    _vm._m(17),
                    _vm._v(" 注："),
                    _c("popover", {
                      attrs: {
                        title: "THP",
                        center: _vm.THPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概3.5万元左右， "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TXH",
                        center: _vm.TXHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概2.7万元左右。 "
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.answer == "ABB"
              ? _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _c("div", { staticClass: "frist-title" }, [
                      _vm._v("早期治疗方案推荐")
                    ]),
                    _c("div", { staticClass: "line-height" }, [
                      _vm._v("未行辅助治疗的治疗方案选择")
                    ]),
                    _vm._v(" （1）腋窝淋巴结阳性，推荐 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-THP",
                        center: _vm.ACTHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: {
                        title: "TCbHP",
                        center: _vm.TCbHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， "),
                    _c("popover", {
                      attrs: {
                        title: "AC-THP",
                        center: _vm.ACTHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案大概四个疗程， 每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛加双靶向用药， 一般每疗程21天，使用大概一年时间，每疗程总花费大概在3.3万元左右；"
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TCbHP",
                        center: _vm.TCbHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案大概六个疗程， 每个疗程21天，每疗程总花费大概在3.3万元左右。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （2）腋窝淋巴结阳性，伴以下危险因素之一：a.T>2cm；b.其他危险因素，如ER阴性，推荐"
                    ),
                    _c("popover", {
                      attrs: {
                        title: "AC-TH",
                        center: _vm.ACTHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， "),
                    _c("popover", {
                      attrs: {
                        title: "TCbH",
                        center: _vm.TCbHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("，其中 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-TH",
                        center: _vm.ACTHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案蒽环类+环磷酰胺大概四个疗程，每个疗程 21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛+曲妥珠单抗， 其中曲妥珠单抗要连续使用一年，每疗程总花费大概在2.7万元左右；"
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TCbH",
                        center: _vm.TCbHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      " 方案一般六个疗程，每疗程21天，其中曲妥珠单抗要连续使用一年。 每疗程总花费大概在2.7万元左右。"
                    ),
                    _c("br"),
                    _vm._v(" （3）腋窝淋巴结阳性且T<2cm，推荐"),
                    _c("popover", {
                      attrs: {
                        title: "TC+H",
                        center: _vm.TCbTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "，方案一般四个疗程， 每疗程21天，其中曲 妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （4）激素受体阳性，且无需化疗或不能耐受化疗者，二级推荐曲妥珠单抗+内分泌治疗。。"
                    ),
                    _c("br"),
                    _c("div", { staticClass: "line-height" }, [
                      _vm._v("使用过新辅助化疗的：")
                    ]),
                    _vm._m(18),
                    _c("div", [_vm._v("化疗方案完成后进行内分泌治疗")]),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show = !_vm.show
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("绝经前点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show
                      ? _c("div", { staticClass: "tab-box" }, [
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("初始治疗：")
                          ]),
                          _vm._m(19),
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("延长治疗：")
                          ]),
                          _vm._m(20),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show1 = true
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("绝经后点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show1
                      ? _c("div", { staticClass: "tab-box" }, [
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("初始治疗：")
                          ]),
                          _vm._m(21),
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("延长治疗：")
                          ]),
                          _vm._m(22),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show1 = !_vm.show1
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "frist-title" }, [
                      _vm._v("晚期治疗方案推荐")
                    ]),
                    _vm._m(23),
                    _vm._v(" 注："),
                    _c("popover", {
                      attrs: {
                        title: "THP",
                        center: _vm.THPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概3.5万元左右， "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TXH",
                        center: _vm.TXHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概2.7万元左右。 "
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show2 = !_vm.show2
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("化疗后开始内分泌治疗点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show2
                      ? _c("div", { staticClass: "tab-box" }, [
                          _vm._m(24),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show2 = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.answer == "BBB"
              ? _c(
                  "div",
                  { staticClass: "center" },
                  [
                    _c("div", { staticClass: "frist-title" }, [
                      _vm._v("早期患者治疗方案推荐")
                    ]),
                    _c("div", { staticClass: "line-height" }, [
                      _vm._v("未行辅助治疗的治疗方案选择")
                    ]),
                    _vm._v(" （1）腋窝淋巴结阳性，推荐 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-THP",
                        center: _vm.ACTHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("、 "),
                    _c("popover", {
                      attrs: {
                        title: "TCbHP",
                        center: _vm.TCbHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， "),
                    _c("popover", {
                      attrs: {
                        title: "AC-THP",
                        center: _vm.ACTHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案大概四个疗程， 每个疗程21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛加双靶向用药，一般每疗程21天，使用大概一年时间，每疗程总花费大概在3.3万元左右；"
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TCbHP",
                        center: _vm.TCbHPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案大概六个疗程， 每个疗程21天，每疗程总花费大概在3.3万元左右。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （2）腋窝淋巴结阳性，伴以下危险因素之一：a.T>2cm；b.其他危险因素，如ER阴 性，推荐"
                    ),
                    _c("popover", {
                      attrs: {
                        title: "AC-TH",
                        center: _vm.ACTHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("， "),
                    _c("popover", {
                      attrs: {
                        title: "TCbH",
                        center: _vm.TCbHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v("，其中 "),
                    _c("popover", {
                      attrs: {
                        title: "AC-TH",
                        center: _vm.ACTHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "方案蒽环类+环磷酰胺大概四个疗程，每个疗程 21天，每疗程总花费大概在5000元左右，四个疗程后序贯使用多西他赛+曲妥珠单抗， 其中曲妥珠单抗要连续使用一年，每疗程总花费大概在2.7万元左右；"
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TCbH",
                        center: _vm.TCbHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      " 方案一般六个疗程，每疗程21天，其中曲妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。"
                    ),
                    _c("br"),
                    _vm._v(" （3）腋窝淋巴结阳性且T<2cm，推荐"),
                    _c("popover", {
                      attrs: {
                        title: "TC+H",
                        center: _vm.TCbTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "，方案一般四个疗程， 每疗程21天，其中曲 妥珠单抗要连续使用一年。每疗程总花费大概在2.7万元左右。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （4）激素受体阳性，且无需化疗或不能耐受化疗者，二级推荐曲妥珠单抗+内分泌治疗。。"
                    ),
                    _c("br"),
                    _c("div", { staticClass: "line-height" }, [
                      _vm._v("使用过新辅助化疗的：")
                    ]),
                    _vm._m(25),
                    _c("div", [_vm._v("化疗方案完成后进行内分泌治疗")]),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show = !_vm.show
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("绝经前点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show
                      ? _c("div", { staticClass: "tab-box" }, [
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("初始治疗：")
                          ]),
                          _vm._m(26),
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("延长治疗：")
                          ]),
                          _vm._m(27),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show1 = !_vm.show1
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("绝经后点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show1
                      ? _c("div", { staticClass: "tab-box" }, [
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("初始治疗：")
                          ]),
                          _vm._m(28),
                          _c("div", { staticClass: "tab-title" }, [
                            _vm._v("延长治疗：")
                          ]),
                          _vm._m(29),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show1 = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "frist-title" }, [
                      _vm._v("晚期患者治疗方案推荐")
                    ]),
                    _vm._m(30),
                    _vm._v(" 注："),
                    _c("popover", {
                      attrs: {
                        title: "THP",
                        center: _vm.THPTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概3.5万元左右， "
                    ),
                    _c("popover", {
                      attrs: {
                        title: "TXH",
                        center: _vm.TXHTips,
                        list: _vm.list
                      }
                    }),
                    _vm._v(
                      "：每疗程21天，具体多少疗程谨遵医嘱，每疗程花费大概2.7万元左右。 "
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mid",
                        on: {
                          click: function($event) {
                            _vm.show2 = !_vm.show2
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-bule" }, [
                          _vm._v("化疗后开始内分泌治疗点击查看详情>>")
                        ]),
                        _vm._v("（方案来源指南，仅供参考，无临床指导意义）")
                      ]
                    ),
                    _vm.show2
                      ? _c("div", { staticClass: "tab-box" }, [
                          _vm._m(31),
                          _c(
                            "div",
                            {
                              staticClass: "show-btn",
                              on: {
                                click: function($event) {
                                  _vm.show2 = false
                                }
                              }
                            },
                            [
                              _vm._v("收起"),
                              _c("van-icon", { attrs: { name: "arrow-up" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wenti-img" }, [
      _c("img", { attrs: { src: require("../../assets/images/xjqr.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wenti-img" }, [
      _c("img", { attrs: { src: require("../../assets/images/xjqr.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("导管原位癌")]),
        _c("td", [_vm._v("全乳放疗±瘤床加量")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("浸润癌，腋窝淋巴结阴性")]),
        _c("td", [
          _c("p", [_vm._v("1、全乳放疗（常规或大分割（首选））±瘤床加量；")]),
          _c("p", [_vm._v("2、部分乳腺短程照射（APBI）")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v("浸润癌，腋窝淋巴结阳性，已清扫")]),
        _c("td", [_vm._v("全乳放疗+瘤床加量+区域淋巴结放疗")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫")]),
        _c("td", [_vm._v("全乳放疗（乳房高位切线野）+瘤床加量")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫")]),
        _c("td")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("腋窝淋巴结清扫术后")]),
          _c("p", [_vm._v("1、T3—T4")]),
          _c("p", [_vm._v("2、腋窝淋巴结阳性")])
        ]),
        _c("td", [_vm._v("胸壁+区域淋巴结放疗")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("前哨淋巴结阳性，未行腋窝淋巴结清扫")]),
        _c("td", [_vm._v(" 胸壁+包括腋窝在内的区域淋巴结放疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("低复发风险患者（全满足）")]),
          _c("p", [_vm._v("1、淋巴结阴性")]),
          _c("p", [_vm._v("2、G1")]),
          _c("p", [_vm._v("3、T≤2cm")]),
          _c("p", [_vm._v("4、Ki-67低表达（≤30%）")])
        ]),
        _c("td", [_vm._v("TAM5年")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("满足以下任意条件者")]),
          _c("p", [_vm._v("1、G2或G3")]),
          _c("p", [_vm._v("2、淋巴结阳性")]),
          _c("p", [_vm._v("3、T>2cm")])
        ]),
        _c("td", [_vm._v(" OFS+TAM5年 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 淋巴结阳性>=4个患者 ")]),
        _c("td", [_vm._v(" OFS+AI5年 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成TAM5年初始治疗且耐受良好患者 ")]),
        _c("td", [
          _c("p", [_vm._v("1、未绝经患者：延长TAM治疗至10年")]),
          _c("p", [_vm._v("2、绝经患者：序贯AI治疗5年")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成OFS+TAM5年初始治疗且耐受良好患者 ")]),
        _c("td", [_vm._v(" 绝经患者：序贯AI治疗 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成OFS+AI5年初始治疗且耐受良好患者 ")]),
        _c("td", [_vm._v(" 绝经患者：AI治疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 全部 ")]),
        _c("td", [
          _c("p", [_vm._v("1、芳香化酶抑制剂（AI）5年")]),
          _c("p", [
            _vm._v(
              "2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年"
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成AI5年初始治疗且耐受良好低危患者 ")]),
        _c("td", [_vm._v(" 可停药 ")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("完成AI5年初始治疗且耐受良好患者，存在以下情况：")]),
          _c("p", [_vm._v("（1）淋巴结阳性")]),
          _c("p", [_vm._v("（2）G3")]),
          _c("p", [_vm._v("（3）其他需要行辅助化疗的危险因素，如KI-67＞30%")])
        ]),
        _c("td", [_vm._v(" 继续AI治疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 未经内分泌治疗 ")]),
        _c("td", [_vm._v(" AI+CDK4/6抑制剂 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" TAM治疗失败 ")]),
        _c("td", [
          _c("p", [_vm._v("AI+CDK4/6抑制剂")]),
          _c("p", [_vm._v("AI+HDAC抑制剂")]),
          _c("p", [_vm._v("氟维司群+CDK4/6抑制剂")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 非甾体类AI治疗失败 ")]),
        _c("td", [
          _c("p", [_vm._v("甾体类AI+HDAC抑制剂")]),
          _c("p", [_vm._v("氟维司群+CDK4/6抑制剂")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 甾体类AI治疗失败 ")]),
        _c("td", [_vm._v(" 氟维司群+CDK4/6抑制剂 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("低复发风险患者（全满足）")]),
          _c("p", [_vm._v("1、淋巴结阴性")]),
          _c("p", [_vm._v("2、G1")]),
          _c("p", [_vm._v("3、T≤2cm")]),
          _c("p", [_vm._v("4、Ki-67低表达（≤30%）")])
        ]),
        _c("td", [_vm._v("TAM5年")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("满足以下任意条件者")]),
          _c("p", [_vm._v("1、G2或G3")]),
          _c("p", [_vm._v("2、淋巴结阳性")]),
          _c("p", [_vm._v("3、T>2cm")])
        ]),
        _c("td", [_vm._v(" OFS+TAM5年 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 淋巴结阳性>=4个患者 ")]),
        _c("td", [_vm._v(" OFS+AI5年 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成TAM5年初始治疗且耐受良好患者 ")]),
        _c("td", [
          _c("p", [_vm._v("1、未绝经患者：延长TAM治疗至10年")]),
          _c("p", [_vm._v("2、绝经患者：序贯AI治疗5年")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成OFS+TAM5年初始治疗且耐受良好患者 ")]),
        _c("td", [_vm._v(" 绝经患者：序贯AI治疗 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成OFS+AI5年初始治疗且耐受良好患者 ")]),
        _c("td", [_vm._v(" 绝经患者：AI治疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 全部 ")]),
        _c("td", [
          _c("p", [_vm._v("1、AI5年")]),
          _c("p", [_vm._v("2、初始使用TAM治疗，可换用AI5年")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成AI5年初始治疗且耐受良好低危患者 ")]),
        _c("td", [_vm._v(" 可停药 ")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("完成AI5年初始治疗且耐受良好患者，存在以下情况：")]),
          _c("p", [_vm._v("（1）淋巴结阳性")]),
          _c("p", [_vm._v("（2）G3")]),
          _c("p", [_vm._v("（3）其他需要行辅助化疗的危险因素，如KI-67＞30%")])
        ]),
        _c("td", [_vm._v(" 继续AI治疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 未经内分泌治疗 ")]),
        _c("td", [_vm._v(" AI+CDK4/6抑制剂 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" TAM治疗失败 ")]),
        _c("td", [
          _c("p", [_vm._v("AI+CDK4/6抑制剂")]),
          _c("p", [_vm._v("AI+HDAC抑制剂")]),
          _c("p", [_vm._v("氟维司群+CDK4/6抑制剂")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 非甾体类AI治疗失败 ")]),
        _c("td", [
          _c("p", [_vm._v("甾体类AI+HDAC抑制剂")]),
          _c("p", [_vm._v("氟维司群+CDK4/6抑制剂")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 甾体类AI治疗失败 ")]),
        _c("td", [_vm._v(" 氟维司群+CDK4/6抑制剂 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-box" }, [
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("术前抗HER-2治疗仅使用曲妥珠单抗")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学完全缓解（pCR） ")]),
          _c("td", [_vm._v("曲妥珠单抗")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学未完全缓解（non pCR） ")]),
          _c("td", [_vm._v(" T-DM1 ")])
        ])
      ]),
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("术前抗HER-2治疗使用曲妥珠单抗联合帕妥珠单抗")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学完全缓解（pCR） ")]),
          _c("td", [_vm._v("HP")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学未完全缓解（non pCR） ")]),
          _c("td", [_vm._v(" T-DM1（恩美曲妥珠单抗） ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-box" }, [
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("患者情况")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [
            _c("p", [_vm._v("1、未用过曲妥珠单抗")]),
            _c("p", [_vm._v("2、曾用曲妥珠单抗且符合再使用标准")])
          ]),
          _c("td", [_c("p", [_vm._v("THP")]), _c("p", [_vm._v("TXH")])])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 曲妥珠单抗治疗失败 ")]),
          _c("td", [_vm._v(" 吡咯替尼+卡培他滨 ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-box" }, [
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("术前抗HER-2治疗仅使用曲妥珠单抗")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学完全缓解（pCR） ")]),
          _c("td", [_vm._v("曲妥珠单抗")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学未完全缓解（non pCR） ")]),
          _c("td", [_c("p", [_vm._v("HP")]), _c("p", [_vm._v("T-DM1")])])
        ])
      ]),
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("术前抗HER-2治疗使用曲妥珠单抗联合帕妥珠单抗")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学完全缓解（pCR） ")]),
          _c("td", [_vm._v("HP")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学未完全缓解（non pCR） ")]),
          _c("td", [_vm._v(" T-DM1（恩美曲妥珠单抗） ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-box" }, [
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("患者情况")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [
            _c("p", [_vm._v("1、未用过曲妥珠单抗")]),
            _c("p", [_vm._v("2、曾用曲妥珠单抗且符合再使用标准")])
          ]),
          _c("td", [_c("p", [_vm._v("THP")]), _c("p", [_vm._v("TXH")])])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 曲妥珠单抗治疗失败 ")]),
          _c("td", [_vm._v(" 吡咯替尼+卡培他滨 ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-box" }, [
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("术前抗HER-2治疗仅使用曲妥珠单抗")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学完全缓解（pCR） ")]),
          _c("td", [_vm._v("曲妥珠单抗")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学未完全缓解（non pCR） ")]),
          _c("td", [_c("p", [_vm._v("HP")]), _c("p", [_vm._v("T-DM1")])])
        ])
      ]),
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("术前抗HER-2治疗使用曲妥珠单抗联合帕妥珠单抗")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学完全缓解（pCR） ")]),
          _c("td", [_vm._v("HP")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学未完全缓解（non pCR） ")]),
          _c("td", [_vm._v(" T-DM1（恩美曲妥珠单抗） ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("低复发风险患者（全满足）")]),
          _c("p", [_vm._v("1、淋巴结阴性")]),
          _c("p", [_vm._v("2、G1")]),
          _c("p", [_vm._v("3、T≤2cm")]),
          _c("p", [_vm._v("4、Ki-67低表达（≤30%）")])
        ]),
        _c("td", [_vm._v("TAM5年")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("满足以下任意条件者")]),
          _c("p", [_vm._v("1、G2或G3")]),
          _c("p", [_vm._v("2、淋巴结阳性")]),
          _c("p", [_vm._v("3、T>2cm")])
        ]),
        _c("td", [_vm._v(" OFS+TAM5年 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("淋巴结阳性>=4个患者")]),
        _c("td", [_vm._v(" OFS+AI5年 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成TAM5年初始治疗且耐受良好患者 ")]),
        _c("td", [
          _c("p", [_vm._v("1、未绝经患者：延长TAM治疗至10年")]),
          _c("p", [_vm._v("2、绝经患者：序贯AI治疗5年")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成OFS+TAM5年初始治疗且耐受良好患者 ")]),
        _c("td", [_vm._v(" 绝经患者：序贯AI治疗 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("完成OFS+AI5年初始治疗且耐受良好患者")]),
        _c("td", [_vm._v(" 绝经患者：AI治疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("全部")]),
        _c("td", [
          _c("p", [_vm._v("1、AI5年")]),
          _c("p", [_vm._v("2、初始使用TAM治疗，可换用AI5年")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成AI5年初始治疗且耐受良好低危患者 ")]),
        _c("td", [_vm._v(" 可停药 ")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("完成AI5年初始治疗且耐受良好患者，存在以下情况：")]),
          _c("p", [_vm._v("（1）淋巴结阳性")]),
          _c("p", [_vm._v("（2）G3")]),
          _c("p", [_vm._v("（3）其他需要行辅助化疗的危险因素，如KI-67＞30%")])
        ]),
        _c("td", [_vm._v(" 继续AI治疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-box" }, [
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("患者情况")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [
            _c("p", [_vm._v("1、未用过曲妥珠单抗")]),
            _c("p", [_vm._v("2、曾用曲妥珠单抗且符合再使用标准")])
          ]),
          _c("td", [_c("p", [_vm._v("THP")]), _c("p", [_vm._v("TXH")])])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 曲妥珠单抗治疗失败 ")]),
          _c("td", [_vm._v(" 吡咯替尼+卡培他滨 ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("未经内分泌治疗")]),
        _c("td", [_vm._v("AI+CDK4/6抑制剂")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("TAM治疗失败")]),
        _c("td", [
          _c("p", [_vm._v("AI+CDK4/6抑制剂")]),
          _c("p", [_vm._v("AI+HDAC抑制剂")]),
          _c("p", [_vm._v("氟维司群+CDK4/6抑制剂")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v("非甾体类AI治疗失败")]),
        _c("td", [
          _c("p", [_vm._v("甾体类AI+HDAC抑制剂")]),
          _c("p", [_vm._v("氟维司群+CDK4/6抑制剂")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v("甾体类AI治疗失败")]),
        _c("td", [_vm._v("氟维司群+CDK4/6抑制剂")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-box" }, [
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("术前抗HER-2治疗仅使用曲妥珠单抗")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学完全缓解（pCR） ")]),
          _c("td", [_vm._v("曲妥珠单抗")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学未完全缓解（non pCR） ")]),
          _c("td", [_c("p", [_vm._v("HP")]), _c("p", [_vm._v("T-DM1")])])
        ])
      ]),
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("术前抗HER-2治疗使用曲妥珠单抗联合帕妥珠单抗")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学完全缓解（pCR） ")]),
          _c("td", [_vm._v("HP")])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 病理学未完全缓解（non pCR） ")]),
          _c("td", [_vm._v(" T-DM1（恩美曲妥珠单抗） ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("低复发风险患者（全满足）")]),
          _c("p", [_vm._v("1、淋巴结阴性")]),
          _c("p", [_vm._v("2、G1")]),
          _c("p", [_vm._v("3、T≤2cm")]),
          _c("p", [_vm._v("4、Ki-67低表达（≤30%）")])
        ]),
        _c("td", [_vm._v("TAM5年")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("满足以下任意条件者")]),
          _c("p", [_vm._v("1、G2或G3")]),
          _c("p", [_vm._v("2、淋巴结阳性")]),
          _c("p", [_vm._v("3、T>2cm")])
        ]),
        _c("td", [_vm._v(" OFS+TAM5年 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("淋巴结阳性>=4个患者")]),
        _c("td", [_vm._v(" OFS+AI5年 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成TAM5年初始治疗且耐受良好患者 ")]),
        _c("td", [
          _c("p", [_vm._v("1、未绝经患者：延长TAM治疗至10年")]),
          _c("p", [_vm._v("2、绝经患者：序贯AI治疗5年")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成OFS+TAM5年初始治疗且耐受良好患者 ")]),
        _c("td", [_vm._v(" 绝经患者：序贯AI治疗 ")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("完成OFS+AI5年初始治疗且耐受良好患者")]),
        _c("td", [_vm._v(" 绝经患者：AI治疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("全部")]),
        _c("td", [
          _c("p", [_vm._v("1、AI5年")]),
          _c("p", [_vm._v("2、初始使用TAM治疗，可换用AI5年")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v(" 完成AI5年初始治疗且耐受良好低危患者 ")]),
        _c("td", [_vm._v(" 可停药 ")])
      ]),
      _c("tr", [
        _c("td", [
          _c("p", [_vm._v("完成AI5年初始治疗且耐受良好患者，存在以下情况：")]),
          _c("p", [_vm._v("（1）淋巴结阳性")]),
          _c("p", [_vm._v("（2）G3")]),
          _c("p", [_vm._v("（3）其他需要行辅助化疗的危险因素，如KI-67＞30%")])
        ]),
        _c("td", [_vm._v(" 继续AI治疗 ")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tab-box" }, [
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("患者情况")]),
          _c("th", [_vm._v("一级推荐")])
        ]),
        _c("tr", [
          _c("td", [
            _c("p", [_vm._v("1、未用过曲妥珠单抗")]),
            _c("p", [_vm._v("2、曾用曲妥珠单抗且符合再使用标准")])
          ]),
          _c("td", [_c("p", [_vm._v("THP")]), _c("p", [_vm._v("TXH")])])
        ]),
        _c("tr", [
          _c("td", [_vm._v(" 曲妥珠单抗治疗失败 ")]),
          _c("td", [_vm._v(" 吡咯替尼+卡培他滨 ")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("th", [_vm._v("患者情况")]),
        _c("th", [_vm._v("一级推荐")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("未经内分泌治疗")]),
        _c("td", [_vm._v("AI+CDK4/6抑制剂")])
      ]),
      _c("tr", [
        _c("td", [_vm._v("TAM治疗失败")]),
        _c("td", [
          _c("p", [_vm._v("AI+CDK4/6抑制剂")]),
          _c("p", [_vm._v("AI+HDAC抑制剂")]),
          _c("p", [_vm._v("氟维司群+CDK4/6抑制剂")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v("非甾体类AI治疗失败")]),
        _c("td", [
          _c("p", [_vm._v("甾体类AI+HDAC抑制剂")]),
          _c("p", [_vm._v("氟维司群+CDK4/6抑制剂")])
        ])
      ]),
      _c("tr", [
        _c("td", [_vm._v("甾体类AI治疗失败")]),
        _c("td", [_vm._v("氟维司群+CDK4/6抑制剂")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }