<template>

    <van-popover v-model="showPopover" trigger="click" placement="bottom-start">
      <div class="tips">
        {{center}}
      </div>
      <template #reference>
        <span class="font-bule">{{ title }}</span>
      </template>
    </van-popover>

</template>

<script>
import Vue from 'vue';
import { Popover } from 'vant';
Vue.use(Popover);
export default {
  name: '',
  data() {
    return {
      showPopover:false
    }
  },
   components: {

   },
  props: {
    title:{
      type: String,
      default: '',
    },
    center:{
      type: String,
      default: '',
    },
  },
  created() {
  },
   mounted() {
  },
  methods: {


  },
  updated(){

  },
}
</script>

<style lang="scss" scoped>
.font-bule{
  color: #1675FF;
  border-bottom: 1px dotted #1675FF;
}
.tips{
  padding:.2rem;
}
</style>
